import React from 'react';
import { Layout } from 'components';

const SamplePage = () => (
  <Layout>
    This is Sample page testing
  </Layout>
);

export default SamplePage;
